import * as React from 'react';
import cx from 'classnames';
import { Link, graphql } from 'gatsby';
import Gutters from '@components/Gutters';
import MaxContent from '@components/MaxContent';
import Layout from '@components/Layout';
import Seo from '@components/Seo';
import Chevron from '@assets/chevron.inline.svg';
import * as styles from './blogPost.module.scss';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title || 'Title';
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle} nobackground>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <MaxContent>
        <Gutters>
          <article
            className={styles.blogpost}
            itemScope
            itemType="http://schema.org/Article"
          >
            <h1 itemProp="headline">{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
            />
            <hr />
            <footer />
          </article>
          <nav className={styles.blogpost__nav}>
            <ul className={styles.blogpost__pagination}>
              <li className={cx(styles.blogpost__arrow, styles.blogpost__arrowPrev)}>
                {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  <Chevron />
                  {' '}
                  {previous.frontmatter.title}
                </Link>
                )}
              </li>
              <li className={cx(styles.blogpost__arrow, styles.blogpost__arrowNext)}>
                {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title}
                  {' '}
                  <Chevron />
                </Link>
                )}
              </li>
            </ul>
          </nav>
        </Gutters>
      </MaxContent>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
